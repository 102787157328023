import React from 'react';

const Footer = () => {
   return (
      <div className= "copyright">
         <p>&copy; 2019</p>
      </div>   
   )
}

export default Footer