import React from 'react';

const Placeholder = () => {
   return (
      <div className= "placeholder-section">
         <p>| | |</p>
      </div>
   )
}

export default Placeholder